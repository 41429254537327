<template>
  <v-row
    wrap
    class="justify-center mt-3"
  >
    <v-col
      class="col-10 pt-0 px-6 mb-6"
    >
      <v-row class="justify-center">
        <v-col>
          <v-card
            v-if="currentWeek && currentWeek.week"
            class="week-container"
          >
            <v-card-title
              primary-title
              class="pb-0"
            >
              <v-col>
                <v-row class="justify-space-between pb-3">
                  <div class="ma-3">
                    <v-btn
                      icon
                      color="primary"
                      :disabled="disableLeftTimeShift"
                      @click="timeShiftLeft"
                    >
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                  </div>

                  <div class="ma-3">
                    <v-row class="mt-0 justify-center primary--text text-uppercase font-weight-bold subtitle-1">
                      {{ getWeekDateRange }}
                    </v-row>

                    <v-row class="mt-0 justify-center coolgray--text overline">
                      {{ timeShiftText }} Week
                    </v-row>

                    <v-row
                      class="mt-0 justify-center coolgray--text overline font-weight-bold"
                    >
                      {{ durationFormatter(currentWeek.total) }} Hours
                    </v-row>
                  </div>

                  <div class="ma-3">
                    <v-btn
                      icon
                      color="primary"
                      :disabled="disableRightTimeShift"
                      @click="timeShiftRight"
                    >
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </div>
                </v-row>

                <v-divider class="mx-2" />
              </v-col>
            </v-card-title>

            <v-card-actions class="px-6">
              <v-col class="pa-0">
                <v-card
                  v-if="week.length && lockedDates.length === week.length"
                  class="elevation-0"
                >
                  <v-card-text>
                    This week is out of range for edits.
                    Message an administrator to request edits to time
                    entries outside the allowed date range.
                  </v-card-text>
                </v-card>

                <v-card
                  v-else-if="week.length && lockedDates.length"
                  class="elevation-0"
                >
                  <v-card-text>
                    Some dates in this week are out of range.
                    Message an administrator to request edits to time
                    entries outside the allowed date range.
                  </v-card-text>
                </v-card>

                <v-card
                  v-for="(weekdate, index) in currentWeek.week"
                  :key="index"
                  :class="`elevation-0 mt-3 ${setEntryClass(weekdate, index)}`"
                >
                  <v-container
                    class="pa-0 ma-0"
                  >
                    <v-card-title
                      primary-title
                      class="py-0"
                    >
                      <v-col>
                        <v-row class="align-center">
                          <div :class="`subtitle-2 ${lockedDates.includes(weekdate.dateValue) ? 'coolgray' : 'darkestblue'}--text`">
                            {{ weekdate.formatted }}
                          </div>

                          <v-spacer />

                          <v-icon
                            text
                            color="primary"
                            :disabled="lockedDates.includes(weekdate.dateValue)"
                            @click="openTimeEntryFormCreate(weekdate.dateValue)"
                          >
                            add
                          </v-icon>
                        </v-row>

                        <v-row class="coolgray--text daily-total">
                          <span class="font-weight-bold pr-1">Hours</span>
                          {{ durationFormatter(weekdate.total) }}
                        </v-row>
                      </v-col>
                    </v-card-title>

                    <v-card-text v-if="weekdate.entries.length">
                      <v-simple-table
                        class="entry-table coolgray--text rounded elevation-1"
                      >
                        <template v-slot:default>
                          <thead class="offwhite">
                            <tr class="">
                              <th class="org-code font-weight-medium">
                                Billing Code
                              </th>

                              <th class="org-name font-weight-medium">
                                Organization
                              </th>

                              <th class="project-name font-weight-medium">
                                Project
                              </th>

                              <th class="time-type font-weight-medium">
                                Time Type
                              </th>

                              <th class="entry-duration font-weight-medium">
                                HH:MM
                              </th>

                              <th class="work-notes font-weight-medium">
                                Notes
                              </th>

                              <th class="entry-actions font-weight-medium">
                                Actions
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="entry in weekdate.entries"
                              :key="entry.timeEntryId"
                            >
                              <td class="org-code py-3">
                                {{ entry.billingCode }}
                              </td>

                              <td class="org-name py-3">
                                {{ entry.clientName }}
                              </td>

                              <td class="project-name py-3">
                                {{ entry.projectName }}
                              </td>

                              <td class="time-type py-3">
                                {{ entry.timeClassificationName }}
                              </td>

                              <td class="entry-duration py-3">
                                {{ durationFormatter(entry.duration) }}
                              </td>

                              <td class="work-notes py-3">
                                {{ entry.description }}
                              </td>

                              <td class="entry-actions py-3">
                                <v-btn
                                  text
                                  x-small
                                  color="red"
                                  :disabled="lockedDates.includes(weekdate.dateValue)"
                                  @click="openTimeEntryFormDelete(entry)"
                                >
                                  <!-- <v-icon>delete</v-icon> -->
                                  Delete
                                </v-btn>

                                <v-btn
                                  text
                                  x-small
                                  color="primary"
                                  :disabled="lockedDates.includes(weekdate.dateValue)"
                                  @click="openTimeEntryFormUpdate(entry)"
                                >
                                  <!-- <v-icon>create</v-icon> -->
                                  Edit
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-container>
                </v-card>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <data-table
            ref="table"
            :actions="actions"
          />
        </v-col>
      </v-row>

      <time-modal
        :time-shift="timeShift"
        :clients="clients"
        :persons="persons"
        :projects="projects"
        :services="services"
        :time-types="timeTypes"
        :time-entries="times"
        :week="week"
      />
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import durations from '../store/utils/durations'

import mixin from '../components/mixin'

const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'TimePage',
  components: {
    DataTable: () => import('src/tools/components/table/DataTable'),
    TimeModal: () => import('src/internal/components/TimeModal'),
  },
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'internal/openTimeEntryFormUpdate',
          name: 'Edit',
        },
        {
          event: 'internal/openTimeEntryFormDelete',
          name: 'Delete',
        },
      ],
      lockedClass: '', // 'offwhite'
    }
  },
  computed: {
    ...mapToolsGetters([
      'currentColumns',
      'currentSummaries',
      'currentViewId',
      'storedItems',
      'organizations',
      'persons',
      'projects',
      'services',
      'times',
      'timeTypes',
    ]),
    timeShift() {
      return this.$store.state.internal?.timeShift || 0
    },
    clients() {
      const allOrgs = this.storedItems?.organizations?.all || []
      return allOrgs.filter((org) => org.isTimesheetEnabled)
    },
    week() {
      return dates.getWeek(this.timeShift)
    },
    disableLeftTimeShift() {
      return this.timeShift < -4
    },
    disableRightTimeShift() {
      return this.timeShift > 4
    },
    timeShiftText() {
      const { timeShift } = this
      const offsetLabels = ['Past', 'Current', 'Next', 'Future', 'Default']

      const past = timeShift < 0 ? 0 : null
      const current = [0, 1].includes(timeShift) ? timeShift + 1 : null
      const future = timeShift > 1 ? 3 : null
      const offsetIndex = future || current || (past === 0 ? past : 4)

      return offsetLabels[offsetIndex]
    },
    getWeekDateRange() {
      const { week } = this
      const [monday,,,,,, sunday] = week
      const { $M: startMonth } = monday || {}
      const { $M: endMonth } = sunday || {}
      const sameMonth = startMonth === endMonth

      return sameMonth
        ? this.sameMonthText(monday, sunday)
        : this.crossMonthText(monday, sunday)
    },
    lockedDates() {
      const { week } = this
      // Get getters dynamically because store is imported dynamically.
      const maxDate = this.$store.getters['internal/maxCalendarDate']
      const minDate = this.$store.getters['internal/minCalendarDate']
      const seed = []

      return week.reduce((locked, weekdate) => {
        const valid = dates.areDatesInRange(weekdate, minDate, maxDate)

        if (!valid) locked.push(weekdate.valueOf())

        return locked
      }, seed)
    },
    currentWeek() {
      const { times, week } = this
      // Prevent pollution during nav.
      const seedWeek = week.map((date) => ({ date, entries: [], formatted: date.format('dddd, MMMM DD'), total: 0 }))
      const currentWeek = { week: seedWeek, total: 0 }

      if (!times.length) return currentWeek

      currentWeek.week = seedWeek.map((day) => {
        const results = this.getEntriesForDay(day.date)
        const totalMinutes = this.getTotal(results)

        currentWeek.total += totalMinutes

        day.dateValue = day.date.valueOf()
        day.entries = results
        day.total += totalMinutes

        return day
      })

      return currentWeek
    },
  },
  methods: {
    openTimeEntryFormCreate(weekdate) {
      return this.$store.dispatch('internal/openTimeEntryFormCreate', weekdate)
    },
    openTimeEntryFormUpdate(entry) {
      return this.$store.dispatch('internal/openTimeEntryFormUpdate', entry)
    },
    openTimeEntryFormDelete(entry) {
      return this.$store.dispatch('internal/openTimeEntryFormDelete', entry)
    },
    timeShiftLeft() {
      const { timeShift } = this

      this.$store.commit('internal/timeShift', timeShift - 1)
    },
    timeShiftRight() {
      const { timeShift } = this

      this.$store.commit('internal/timeShift', timeShift + 1)
    },

    sameMonthText: (monday, sunday) => `${dates.getFormattedDate(monday, 'MMMM DD')}-${dates.getFormattedDate(sunday, 'DD, YYYY')}`,
    crossMonthText: (monday = {}, sunday = {}) => {
      const { $y: startYear } = monday
      const { $y: endYear } = sunday
      const sameYear = startYear === endYear
      const startFormat = sameYear ? 'MMMM DD' : 'MMMM DD, YYYY'
      const endFormat = sameYear ? 'DD, YYYY' : 'MMMM DD, YYYY'

      return `${dates.getFormattedDate(monday, startFormat)}-${dates.getFormattedDate(sunday, endFormat)}`
    },

    getProjectName(projectId) {
      const { projects } = this
      const project = projects.find((p) => p.projectId === projectId)

      if (!project) return `Project ID ${projectId}`

      return project.projectName
    },

    getEntriesForDay(weekdate) {
      const matchDate = (entry) => dates.areDatesEqual(entry.timeEntryDate, weekdate)
      return this.times.filter(matchDate)
    },
    getAllEntriesFromCurrentWeek() {
      return this.currentWeek.reduce((entries, day) => {
        entries = entries.concat(day.entries)

        return entries
      }, [])
    },

    setEntryClass(weekdate, index) {
      const { lockedClass, lockedDates } = this
      const calendarDate = weekdate.valueOf()
      const isLocked = lockedDates.includes(calendarDate)
      const colored = isLocked ? lockedClass : 'white' // 'icewhite'
      const uncolored = isLocked ? lockedClass : 'white'
      const striping = index % 2 === 0 ? colored : uncolored

      return striping
    },

    durationFormatter(duration) {
      return durations.durationFormatter(duration)
    },

    getTotal(entries) {
      const seed = 0

      return entries.reduce((total, entry) => {
        total += entry.duration

        return total
      }, seed)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.hours-label {
  font-size: small;
  margin-left: 1ch;
}

.week-container {
  width: 100%;

  .container {
    max-width: 100%;
  }
}

.daily-total {
  font-size: 12px;
  text-transform: uppercase;
}

.v-data-table {
  width: 100%;

  ::v-deep th {
    font-size: xx-small;
    word-wrap: break-word;
    word-break: break-word;
  }

  ::v-deep td {
    font-size: 11px;
    word-wrap: break-word;
    word-break: break-word;
  }

  ::v-deep.time-entry-id {
    min-width: 45px;
    max-width: 45px;
  }

  ::v-deep.org-code {
    min-width: 14ch;
    max-width: 14ch;
  }

  ::v-deep.org-name {
    min-width: 15ch;
    max-width: 15ch;
  }

  ::v-deep.time-entry-date {
    min-width: 102px;
    max-width: 102px;
  }

  ::v-deep.entry-duration {
    min-width: 75px;
    max-width: 75px;
  }

  ::v-deep.time-type {
    min-width: 120px;
    max-width: 120px;
  }

  ::v-deep.project-name {
    min-width: 120px;
    max-width: 120px;
  }

  ::v-deep.work-notes {
    min-width: 130px;
    max-width: 130px;
  }

  .entry-actions {
    text-align: right;
    min-width: 150px;
  }

  .v-icon {
    font-size: 18px;
  }

  .v-btn {
    margin-top: -3px;
  }
}

</style>
