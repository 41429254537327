<template>
  <v-card
    class="elevation-0 mx-3"
  >
    <v-card-title class="body-2">
      <v-icon class="pr-2">
        error_outline
      </v-icon>

      Error loading data. Please try again later or

      <a
        class="pl-1"
        href="mailto:tech@oao.freshdesk.com"
        target="_blank"
      >
        contact us
      </a>.
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'InternalError',
}
</script>
