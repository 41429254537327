import { createNamespacedHelpers } from 'vuex'

import mixin from 'src/utils/mixin'

import DataTable from 'src/tools/components/table/DataTable'
import SummaryCard from 'src/tools/components/summary/SummaryCard'
import SummaryCardBar from 'src/tools/components/summary/SummaryCardBar'

import InternalError from './Error'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  components: {
    InternalError,
    SummaryCard,
    SummaryCardBar,
    DataTable,
  },
  computed: {
    ...mapGetters(['currentColumns', 'currentSummaries']),
    ...mapState({
      displayFilter: (state) => state.displayFilter,
      filterData: (state) => state.filterData,
      selectedSummary: (state) => state.selectedSummary,
    }),
  },
  ...mixin,
}
